import { graphql, Link } from 'gatsby';
import React from 'react';
import IFUHomeBtn from '../components/ifu-home-btn/ifu-home-btn';
import ContentfulProductLine from '../models/contentful/product-line';

export default ({ data }: any) => {
  const productLine = new ContentfulProductLine(data.productLine);

  return (
    <>
      <IFUHomeBtn />
      <div className="container py-5">
        <h2>{productLine.name}</h2>
      </div>
      <div className="filter-block">
        {productLine.products.map((node: any, index: number) => {
          return (
            <Link
              key={`product-info-${index}`}
              to={`/${productLine.slug}/${node.slug}`}
              className="ifu-filters-blocks link-plain">
              <div className="container">{node.name}</div>
            </Link>
          );
        })}
      </div>
    </>
  );
};

export const query = graphql`
  query($slug: String!) {
    productLine: contentfulProductLine(slug: { eq: $slug }) {
      slug
      name
      products {
        name
        slug
        ifuCode
      }
    }
  }
`;
