import IContentfulProduct from '../../interfaces/ContentfulProduct';
import IContentfulProductLine from '../../interfaces/ContentfulProductLine';
import ContentfulProduct from './product';
export default class ContentfulProductLine {
  name: string;
  slug: string;
  products: ContentfulProduct[];

  constructor(item: IContentfulProductLine) {
    this.name = item.name || '';
    this.slug = item.slug || '';
    this.products =
      item.products && item.products.length
        ? item.products.map((product: IContentfulProduct) => new ContentfulProduct(product))
        : [];
  }
}
